import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import ContentHero from '../components/content-hero';

import PricingCards from '../components/pricing-cards';
import Feature from '../components/feature';

import otherFeatureVisualContent from '../images/other-features.svg';

const pricingCards = [
  {
    title: 'For individual students and teachers',
    text: 'Prove that you\'re a student or a teacher and automatically get a 75% discount on all our plans.',
    price: '4',
    priceUnit: '$',
    priceAdd: '/month paid annually',
    promo: {
      firstLine: '-75%',
      secondLine: 'off - oh yeah!',
    },
    description: {
      // baseline: 'This is what you want',
      // title: 'This is what you want',
      // text: 'Get the full Ludus experience like anybody else. Just cheaper.',
      linkLabel: 'What do I get?',
      linkUrl: '/product-tour',
      buttonLabel: 'Claim my 75% discount',
      buttonUrl: '/contact',
    }
  },
  {
    title: 'For schools/universities',
    text: 'Buy a number accounts for your organization by whitelisting your domain name for a flat price, so your students and teachers can subscribe and use Ludus for free.',
    priceText: 'Customized price',
    promo: {
      firstLine: null,
      secondLine: 'Special price',
    },
    description: {
      // baseline: 'This is what you want',
      // title: 'This is what you want',
      // text: 'Get the full Ludus experience like anybody else. Just cheaper.',
      linkLabel: 'What do we get?',
      linkUrl: '/product-tour',
      buttonLabel: 'Contact us',
      buttonUrl: '/contact',
    }
  }
];

const Education = () => {

  return (
    <Layout
      socialDark={true}
      currentPage='education'
    >
      <SEO title="Education" />

      <Section>
        <ContentHero
          visual="education-visual.jpg"
          visualAlt="Education"
          title="Education"
          text="<p>Our special pricing for students and teachers</p>"
        />
      </Section>

      <Section>
        <PricingCards cards={pricingCards} />
      </Section>
      
      <Section>
        <div style={{
          position: 'absolute',
          zIndex: '1',
          width: '39%',
          paddingBottom: '61%',
          bottom: '0',
          left: '0',
          background: `transparent url(${otherFeatureVisualContent}) no-repeat 0 50%`,
          backgroundSize: 'contain',
        }} />

        <Feature 
          reverse={true}
          extraspace={true}
          title="There is so much more!"
          text="We can't list you every single one of our feature can we? You really want us to do it? Ok so here you go..."
          url="/all-features"
          link="See everything"
        />
      </Section>

      
    </Layout>
  )
};

export default Education
